<template>
  <div class="viewer">
    <div v-if="shown" class="image-viewer" @click="close">
      <div ref="imageBoxRef" class="image-box">
        <img v-if="shown" ref="imgRef"
             :src="url"
             alt=""
             class="img"
             :class="[imgSize]"
             @load="imgLoaded"/>
      </div>

      <span class="text">点击任意处关闭</span>
    </div>

    <div v-if="!hidePlaceholder" class="pointer" @click="show()">
      <img v-if="!hidePlaceholderImg"
           :src="url"
           alt=""
           class="placeholder-img"/>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
    url: { type: String, required: true },
    hidePlaceholderImg: { type: Boolean, default: false },
    hidePlaceholder: { type: Boolean, default: false },
  },
  data() {
    return {
      shown: false,
      imgSize: '',
    };
  },
  mounted() {
    window.addEventListener('resize', this.imgLoaded);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.imgLoaded);
  },
  methods: {
    show() {
      this.shown = true;
    },
    close() {
      this.imgSize = '';
      this.shown = false;
    },

    imgLoaded() {
      const boxEl = this.$refs.imageBoxRef;
      const el = this.$refs.imgRef;
      if (!boxEl || !el) {
        return;
      }

      const boxRate = boxEl.clientWidth / boxEl.clientHeight;
      const imgRate = el.clientWidth / el.clientHeight;

      if (boxRate > imgRate) {
        this.imgSize = 'height';
      } else {
        this.imgSize = 'width';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.viewer {
  display: inline-flex;
}

.placeholder-img {
  max-width: 120px;
  max-height: 120px;
}

.image-viewer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  .image-box {
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 80%;
    text-align: center;
  }

  .img {
    flex: none;
    max-width: 100%;
    max-height: 100%;

    &.width {
      width: 100%;
    }

    &.height {
      height: 100%;
    }
  }

  .text {
    margin-top: 6px;
    padding: 6px 12px;
    border-radius: 2px;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.35);
  }
}
</style>
