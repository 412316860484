import Frame from '@/frame/Index.vue';

import PostDeletedLog from '@/views/user/post/DeletedRecord.vue';

const route = {
  path: '/frame',
  component: Frame,
  children: [
    {
      path: 'post-del-log',
      component: PostDeletedLog,
    },
  ],
};

export default route;
